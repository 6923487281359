import AccessControl from "accesscontrol";
import { UserRole } from "services/types";

const { ROLE_EDUCATOR, ROLE_SCHOOL_ADMIN, ROLE_SUPER_ADMIN, ROLE_SUPPORT_ADMIN, ROLE_LEARNER, ROLE_TEAMMATE } =
  UserRole;

// features
const SCHOOL_ADMIN = "SCHOOL_ADMIN";
const MTC_TEAM = "MTC_TEAM";
const EDUCATOR = "EDUCATOR";
const LEARNER = "LEARNER";
const LEARNER_PROFILE_REVISION = "LEARNER_PROFILE_REVISION";
const LEARNER_STATEMENT = "LEARNER_STATEMENT";
const COLLEGES = "COLLEGES";
const COURSE = "COURSE"; // school courses
const EXPERIENCE = "EXPERIENCE";
const SCHOOL_MCA = "SCHOOL_MCA";
const MCA = "MCA";
const EVIDENCE_COMMENT = "EVIDENCE_COMMENT";
const SCHOOL_SETTINGS = "SCHOOL_SETTINGS";
const TRANSCRIPT = "TRANSCRIPT";
const TRANSCRIPT_ACCESS_PDF = "TRANSCRIPT_ACCESS_PDF";
const LEARNING_RECORD = "LEARNING_RECORD";
const LEARNING_RECORD_ACCESS_PDF = "LEARNING_RECORD_ACCESS_PDF";
const LEARNER_COURSE = "LEARNER_COURSE";
const SCHOOL_COLLEGE = "SCHOOL_COLLEGE";
const SCHOOL = "SCHOOL";
const SCHOOL_PDF = "SCHOOL_PDF";
const EVIDENCE = "EVIDENCE";
const LEARNER_CREDIT = "CREDIT";
const LEARNER_COLLEGE = "LEARNER_COLLEGE";
const MTC_GROW = "MTC_GROW";
const SCHOOL_GROW = "SCHOOL_GROW";
const RESOURCE = "RESOURCE";
const SCHOOL_EVENTS = "SCHOOL_EVENTS";
const PROGRESS_REPORT = "PROGRESS_REPORT";

const grantsObject = {
  [ROLE_SUPER_ADMIN]: {
    [SCHOOL_PDF]: { "read:any": ["*"] },
    [SCHOOL_GROW]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [SCHOOL]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [SCHOOL_COLLEGE]: { "read:any": ["*"] },
    [MTC_TEAM]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
    },
    [SCHOOL_ADMIN]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
    },
    [EDUCATOR]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNER]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNER_STATEMENT]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNER_PROFILE_REVISION]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [COURSE]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [EXPERIENCE]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNER_COURSE]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [MCA]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [SCHOOL_MCA]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [TRANSCRIPT_ACCESS_PDF]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNING_RECORD_ACCESS_PDF]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [SCHOOL_SETTINGS]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [TRANSCRIPT]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNING_RECORD]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [EVIDENCE_COMMENT]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*", "!inTranscript"],
      "delete:any": ["*"],
    },
    [EVIDENCE]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNER_CREDIT]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNER_COLLEGE]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [MTC_GROW]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [COLLEGES]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [RESOURCE]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [PROGRESS_REPORT]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
  },
  [ROLE_TEAMMATE]: {
    [SCHOOL_PDF]: {
      "read:any": ["*"],
    },
    [SCHOOL]: {
      "read:any": ["*"],
    },
    [SCHOOL_GROW]: {
      "read:any": ["*"],
    },
    [SCHOOL_COLLEGE]: {
      "read:any": ["*"],
    },
    [SCHOOL_ADMIN]: {
      "read:any": ["*"],
    },
    [MTC_TEAM]: {
      "read:any": ["*"],
    },
    [EDUCATOR]: {
      "read:any": ["*"],
    },
    [LEARNER]: {
      "read:any": ["*"],
    },
    [LEARNER_STATEMENT]: {
      "read:any": ["*"],
    },
    [LEARNER_PROFILE_REVISION]: {
      "read:any": ["*"],
    },
    [COURSE]: {
      "read:any": ["*"],
    },
    [EXPERIENCE]: {
      "read:any": ["*"],
    },
    [LEARNER_COURSE]: {
      "read:any": ["*"],
    },
    [MCA]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [SCHOOL_MCA]: {
      "read:any": ["*"],
    },
    [SCHOOL_SETTINGS]: {},
    [EVIDENCE_COMMENT]: {
      "read:any": ["*"],
    },
    [EVIDENCE]: {
      "read:any": ["*"],
    },
    [LEARNER_CREDIT]: {
      "read:any": ["*"],
    },
    [LEARNER_COLLEGE]: {
      "read:any": ["*"],
    },
    [COLLEGES]: {
      "read:own": ["*"],
    },
    [MTC_GROW]: {
      "read:any": ["*"],
    },
    [RESOURCE]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
  },
  [ROLE_SUPPORT_ADMIN]: {
    [SCHOOL_PDF]: {
      "read:any": ["*"],
    },
    [SCHOOL]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [SCHOOL_COLLEGE]: {
      "read:any": ["*"],
    },
    [SCHOOL_ADMIN]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
    },
    [EDUCATOR]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNER]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNER_STATEMENT]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNER_PROFILE_REVISION]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [COURSE]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [EXPERIENCE]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNER_COURSE]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [MCA]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [SCHOOL_MCA]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [TRANSCRIPT_ACCESS_PDF]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNING_RECORD_ACCESS_PDF]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [EVIDENCE_COMMENT]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:own": ["*", "!inTranscript"],
      "delete:own": ["*"],
    },
    [SCHOOL_SETTINGS]: {
      "read:any": ["*"],
    },
    [TRANSCRIPT]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNING_RECORD]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [EVIDENCE]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNER_CREDIT]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNER_COLLEGE]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [MTC_GROW]: {
      "read:any": ["*"],
    },
    [COLLEGES]: {
      "read:own": ["*"],
    },
    [SCHOOL_GROW]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [RESOURCE]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [PROGRESS_REPORT]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
  },
  [ROLE_SCHOOL_ADMIN]: {
    [SCHOOL]: {
      "update:any": ["*"],
    },
    [SCHOOL_PDF]: {
      "read:any": ["*"],
    },
    [SCHOOL_COLLEGE]: {
      "read:any": ["*"],
    },
    [SCHOOL_ADMIN]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
    },
    [EDUCATOR]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNER]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNER_STATEMENT]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNER_PROFILE_REVISION]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [COURSE]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [EXPERIENCE]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNER_COURSE]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [MCA]: {
      "read:any": ["*"],
    },
    [SCHOOL_MCA]: {
      "create:own": ["*"],
      "read:any": ["*"],
      "update:own": ["*"],
      "delete:own": ["*"],
    },
    [TRANSCRIPT_ACCESS_PDF]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNING_RECORD_ACCESS_PDF]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [EVIDENCE_COMMENT]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*", "!inTranscript"],
      "delete:own": ["*"],
    },
    [SCHOOL_SETTINGS]: {
      "read:any": ["*"],
    },
    [TRANSCRIPT]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNING_RECORD]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [EVIDENCE]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNER_CREDIT]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNER_COLLEGE]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [MTC_GROW]: {
      "read:any": ["*"],
    },
    [COLLEGES]: {
      "read:own": ["*"],
    },
    [SCHOOL_GROW]: {
      "create:own": ["*"],
      "read:own": ["*"],
      "update:own": ["*"],
      "delete:own": ["*"],
    },
    [RESOURCE]: {
      "read:any": ["*"],
    },
    [SCHOOL_EVENTS]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [PROGRESS_REPORT]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
  },
  [ROLE_EDUCATOR]: {
    [SCHOOL_COLLEGE]: { "read:any": ["*"] },
    [EDUCATOR]: {
      "read:any": ["*"],
      "update:own": ["*"],
    },
    [SCHOOL_ADMIN]: {
      "read:any": ["*"],
    },
    [LEARNER]: {
      "read:any": ["*"],
      "update:any": ["*", "!status"],
    },
    [LEARNER_STATEMENT]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNER_PROFILE_REVISION]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [COURSE]: {
      "read:any": ["*"],
    },
    [EXPERIENCE]: {
      "read:any": ["*"],
    },
    [LEARNER_COURSE]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
    },
    [MCA]: {
      "read:any": ["*"],
    },
    [SCHOOL_MCA]: {
      "read:any": ["*"],
    },
    [EVIDENCE_COMMENT]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:own": ["*", "!inTranscript"],
      "delete:own": ["*"],
    },
    [EVIDENCE]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNER_CREDIT]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [LEARNER_COLLEGE]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
    [COLLEGES]: {
      "read:own": ["*"],
    },
    [MTC_GROW]: {
      "read:any": ["*"],
    },
    [SCHOOL_GROW]: {
      "read:own": ["*"],
    },
    [RESOURCE]: {
      "read:any": ["*"],
    },
    [SCHOOL_EVENTS]: {
      "create:any": ["*"],
      "read:any": ["*"],
      "update:any": ["*"],
      "delete:any": ["*"],
    },
  },
  [ROLE_LEARNER]: {
    [LEARNER]: {
      "read:own": ["*"],
      "update:own": ["photoUrl"],
    },
    [LEARNER_COLLEGE]: {
      "create:own": ["*"],
      "read:own": ["*"],
      "update:own": ["*"],
      "delete:own": ["*"],
    },
    [LEARNER_STATEMENT]: {
      "create:own": ["*"],
      "read:own": ["*"],
      "update:own": ["*"],
      "delete:own": ["*"],
    },
    [LEARNER_PROFILE_REVISION]: {
      "create:own": ["*"],
      "read:own": ["*"],
      "update:own": ["*"],
      "delete:own": ["*"],
    },
    [COURSE]: {
      "read:any": ["*"],
    },
    [EXPERIENCE]: {
      "read:any": ["*"],
    },
    [LEARNER_COURSE]: {
      "read:own": ["*"],
      "delete:own": ["*"],
    },
    [COLLEGES]: {
      "read:own": ["*"],
    },
    [SCHOOL_MCA]: {
      "read:own": ["*"],
    },
    [EVIDENCE]: {
      "create:own": ["*"],
      "read:own": ["*"],
      "update:own": ["*"],
      "delete:own": ["*"],
    },
    [EVIDENCE_COMMENT]: {
      "read:any": ["*"],
      "update:any": ["inTranscript"],
    },
    // [SCHOOL_GROW]: {
    //   "read:any": ["*"],
    //   "update:any": ["inTranscript"],
    // },
  },
};

const RBAC = new AccessControl();
RBAC.setGrants(grantsObject);
RBAC.lock();

const getPermission = (action, resource, role, isOwner) =>
  isOwner ? RBAC.can(role)[`${action}Own`](resource) : RBAC.can(role)[`${action}Any`](resource);

/**
 * Returns actions by user role and entity status
 *
 * @param {Map} config config with roles and statuses
 * @param {String} status - entity status
 * @param {String} role - user role
 * @returns {Array}
 *
 * @example
 * const primaryActionsByRoleAndStatus = new Map([
 *  [
 *    [role1],
 *    {
 *      [status1]: [action1, action2],
 *    },
 *  ],
 *  [
 *    [role2, role3],
 *    {
 *      [status1]: [action1, action3],
 *      [status2]: [action2, action2],
 *      [status3]: [action3, action4],
 *    },
 *  ],
 * ]
 * const status = 'status1'
 * const role = 'role2'
 *
 * const actions = getActionsByStatusAndRole(primaryActionsByRoleAndStatus, status, role)
 * returns [action1, action3]
 */
export const getActionsByStatusAndRole = (config, status, role) => {
  for (const [authorizations, actionsByStatus] of config.entries()) {
    if (authorizations.includes(role)) {
      return actionsByStatus?.[status] ?? [];
    }
  }

  return [];
};

export {
  MTC_TEAM,
  SCHOOL_ADMIN,
  EDUCATOR,
  LEARNER,
  LEARNER_PROFILE_REVISION,
  COLLEGES,
  COURSE,
  EXPERIENCE,
  SCHOOL_MCA,
  MCA,
  TRANSCRIPT_ACCESS_PDF,
  SCHOOL_SETTINGS,
  TRANSCRIPT,
  EVIDENCE_COMMENT,
  LEARNER_COURSE,
  SCHOOL_COLLEGE,
  SCHOOL,
  SCHOOL_PDF,
  EVIDENCE,
  LEARNER_CREDIT,
  LEARNER_COLLEGE,
  MTC_GROW,
  SCHOOL_GROW,
  RESOURCE,
  getPermission,
  RBAC,
  SCHOOL_EVENTS,
  LEARNING_RECORD,
  LEARNING_RECORD_ACCESS_PDF,
  PROGRESS_REPORT,
};
