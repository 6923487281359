import clsx from "clsx";
import React, { SVGProps } from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

import { ReactComponent as Add } from "./add.svg";
import { ReactComponent as AddCircle } from "./addCircle.svg";
import { ReactComponent as CalendarEdit } from "./calendarEdit.svg";
import { ReactComponent as Delete } from "./bin.svg";
import { ReactComponent as DeleteUser } from "./deleteUser.svg";
import { ReactComponent as Edit } from "./edit.svg";
import { ReactComponent as EditFilled } from "./editFilled.svg";
import { ReactComponent as MoveRight } from "./moveRight.svg";
import { ReactComponent as SendInvite } from "./sendInvite.svg";
import { ReactComponent as ArchiveUser } from "./archiveUser.svg";
import { ReactComponent as ArchiveDrawer } from "./archiveDrawer.svg";
import { ReactComponent as ResetPassword } from "./resetPassword.svg";
import { ReactComponent as View } from "./view.svg";
import { ReactComponent as ViewOff } from "./viewOff.svg";
import { ReactComponent as TemplateDownload } from "./templateDownload.svg";
import { ReactComponent as Remove } from "./remove.svg";
import { ReactComponent as AwardRibbon } from "./awardRibbon.svg";
import { ReactComponent as CertifiedCertificate } from "./certifiedCertificate.svg";
import { ReactComponent as FileDownload } from "./commonFileDownload.svg";
import { ReactComponent as Info } from "./informationCircle.svg";
import { ReactComponent as PencilCircle } from "./pencilCircle.svg";
import { ReactComponent as UploadSquare } from "./uploadSquare.svg";
import { ReactComponent as UploadBox } from "./upload.svg";
import { ReactComponent as Filter } from "./filterText.svg";
import { ReactComponent as Search } from "./search.svg";
import { ReactComponent as AddPhoto } from "./camera.svg";
import { ReactComponent as Calendar } from "./calendar.svg";
import { ReactComponent as Close } from "./close.svg";
import { ReactComponent as KeyboardArrowUp } from "./keyboardArrowUp.svg";
import { ReactComponent as ArrowsDownBold } from "./arrowsDownBold.svg";
import { ReactComponent as NavigationLeft } from "./navigationLeft.svg";
import { ReactComponent as VerifiedBadge } from "./verifiedBadge.svg";
import { ReactComponent as EmailSent } from "./emailSent.svg";
import { ReactComponent as SchoolPlaceholder } from "./school.svg";
import { ReactComponent as QuestionCircle } from "./questionCircle.svg";
import { ReactComponent as CreditLibraryHidden } from "./creditLibraryHidden.svg";
import { ReactComponent as CreditsExcluded } from "./creditsExcluded.svg";
import { ReactComponent as FileAttachment } from "./fileAttachment.svg";
import { ReactComponent as FeedRequiredAction } from "./feedRequiredAction.svg";
import { ReactComponent as CourseEventChip } from "./courseEventChip.svg";
import { ReactComponent as EvidenceEventChip } from "./evidenceEventChip.svg";
import { ReactComponent as FoundationalCreditEventChip } from "./foundationalCreditEventChip.svg";
import { ReactComponent as InitiatorEventChip } from "./initiatorEventChip.svg";
import { ReactComponent as LearnerEventChip } from "./learnerEventChip.svg";
import { ReactComponent as AdvancedCreditEventChipIcon } from "./advancedCreditEventChipIcon.svg";
import { ReactComponent as Deny } from "./deny.svg";
import { ReactComponent as Google } from "./google.svg";
import { ReactComponent as UpdateSchoolsList } from "./updateSchoolsList.svg";
import { ReactComponent as EditSchoolContactsTab } from "./editSchoolContactsTab.svg";
import { ReactComponent as EditSchoolGeneralTab } from "./editSchoolGeneralTab.svg";
import { ReactComponent as EditSchoolInfoTab } from "./editSchoolInfoTab.svg";
import { ReactComponent as EditSchoolMcaTab } from "./editSchoolMcaTab.svg";
import { ReactComponent as EditSchoolNumbersTab } from "./editSchoolNumbersTab.svg";
import { ReactComponent as EditSchoolSystemsTab } from "./editSchoolSystemsTab.svg";
import { ReactComponent as CollegeEventChip } from "./collegeEventChip.svg";
import { ReactComponent as Restore } from "./restore.svg";
import { ReactComponent as ClrDownload } from "./clrDownload.svg";
import { ReactComponent as CreditArchived } from "./creditArchived.svg";
import { ReactComponent as AddCreditLabel } from "./addCreditLabel.svg";
import { ReactComponent as MarkAsFeaturedCredit } from "./ratingStarCheck.svg";
import { ReactComponent as markAsNotFeaturedCredit } from "./ratingStarSubtract.svg";
import { ReactComponent as Comment } from "./comment.svg";
import { ReactComponent as Email } from "./readEmail.svg";
import { ReactComponent as EditorToolbarAttachImage } from "./takingPicturesSquare.svg";
import { ReactComponent as File } from "./file.svg";
import { ReactComponent as Folder } from "./folder.svg";
import { ReactComponent as ExternalLink } from "./externalLink.svg";
import { ReactComponent as LibraryDownload } from "./libraryDownload.svg";
import { ReactComponent as LoginAs } from "./loginAs.svg";
import { ReactComponent as Like } from "./like.svg";
import { ReactComponent as Messages } from "./messages.svg";
import { ReactComponent as GrowCollection } from "./growCollection.svg";
import { ReactComponent as Leave } from "./leave.svg";
import { ReactComponent as ChangeStatus } from "./changeStatus.svg";
import { ReactComponent as CheckCircle } from "./checkCircle.svg";
import { ReactComponent as MarkAsDemo } from "./markAsDemo.svg";
import { ReactComponent as TaskChecklist } from "./taskChecklist.svg";
import { ReactComponent as Retry } from "./retry.svg";
import { ReactComponent as UnreadIndicator } from "./unreadIndicator.svg";
import { ReactComponent as Logo } from "./logo.svg";
import { ReactComponent as MobileLogo } from "./logoMobile.svg";
import { ReactComponent as EnableCreditTypes } from "./enableCreditTypes.svg";
import { ReactComponent as DisableCreditTypes } from "./disableCreditTypes.svg";
import { ReactComponent as CalendarClock } from "./calendarClock.svg";
import { ReactComponent as PriorityCredit } from "./priorityCredit.svg";
import { ReactComponent as AddDemonstratedLevel } from "./addDemonstrateLevel.svg";
import { ReactComponent as Back } from "./back.svg";
import { ReactComponent as AnalyticBarsWhite } from "./analyticsBarsHorizontalWhite.svg";
import { ReactComponent as WhiteLogo } from "./whiteLogo.svg";

// Transcript icons
import { ReactComponent as OfficeFilePdf } from "./officeFilePdf.svg";
import { ReactComponent as AccessPagePDf } from "./accessPagePdf.svg";
import { ReactComponent as PublishPDF } from "./publishPDF.svg";
import { ReactComponent as ErrorTranscriptField } from "./errorTranscriptField.svg";
import { ReactComponent as WarningTranscriptField } from "./warningTranscriptField.svg";
import { ReactComponent as FeedAll } from "./feedAll.svg";
import { ReactComponent as TranscriptPublishHidden } from "./transcriptPublishHidden.svg";
import { ReactComponent as ViewCircle } from "./viewCircle.svg";

// Lock/unlock
import { ReactComponent as Lock } from "./lock1.svg";
import { ReactComponent as LockUnlock } from "./lockUnlock.svg";

// Import results icons
import { ReactComponent as Check } from "./check1.svg";
import { ReactComponent as Block } from "./block.svg";

// school contact icons
import { ReactComponent as ContactCard } from "./neutralIdCard4.svg";
import { ReactComponent as ContactPhone } from "./phoneCircle.svg";
import { ReactComponent as ContactWebSite } from "./networkBrowser.svg";

// View switcher icons
import { ReactComponent as CardView } from "./layoutAgenda.svg";
import { ReactComponent as TableView } from "./layoutBullets.svg";
import { ReactComponent as MapView } from "./layoutMap.svg";

// Credit badge icons
import { ReactComponent as AdvancedCredit } from "./advancedCredit.svg";
import { ReactComponent as FoundationalCredit } from "./foundationalCredit.svg";
import { ReactComponent as Stellar } from "./stellar.svg";
import { ReactComponent as EmptyStellar } from "./stellar_empty.svg";

// menu icons
import { ReactComponent as MenuButton } from "./menuButton.svg";
import { ReactComponent as MenuSchool } from "./menuSchool.svg";
import { ReactComponent as MenuAdmins } from "./menuAdmins.svg";
import { ReactComponent as MenuLearner } from "./menuLearner.svg";
import { ReactComponent as MenuEducator } from "./menuEducator.svg";
import { ReactComponent as MenuCourses } from "./menuCourses.svg";
import { ReactComponent as MenuMca } from "./menuMca.svg";
import { ReactComponent as MenuColleges } from "./menuColleges.svg";
import { ReactComponent as MenuSchoolsAdmins } from "./menuSchoolsAdmins.svg";
import { ReactComponent as MenuCredits } from "./menuCredits.svg";
import { ReactComponent as MenuCreditAreas } from "./menuCreditAreas.svg";
import { ReactComponent as MenuLiveFeed } from "svg/menuLiveFeed.svg";
import { ReactComponent as Group } from "./menuCommunityGroups.svg";
import { ReactComponent as MenuMediaLibrary } from "./menuMediaLibrary.svg";
import { ReactComponent as MenuGrow } from "./menuGrow.svg";
import { ReactComponent as MenuEvents } from "./menuEvents.svg";

// Dashboard icons
import { ReactComponent as Dashboard } from "./dashboard.svg";

// Republish document icons
import { ReactComponent as CommonFileCheck } from "./commonFileCheck.svg";
import { ReactComponent as CommonFileWarning } from "./commonFileWarning.svg";
import { ReactComponent as CommonFileDisable } from "./commonFileDisable.svg";
import { ReactComponent as DeleteCircle } from "./deleteCircle.svg";

import { ReactComponent as AnalyticsBarsHorizontal } from "./analyticsBarsHorizontal.svg";

// Learning Scale icons
import { ReactComponent as UnsyncedProgress } from "./unsyncedProgress.svg";
import { ReactComponent as LearningScaleEnabled } from "./learningScaleEnabled.svg";

const withSvgIconComponent =
  (
    Icon: React.FC<SVGProps<SVGSVGElement> & { title?: string | undefined }>,
    type?: "stroke" | null,
    viewBox = "0 0 24 24"
  ) =>
  ({ className, ...props }: SvgIconProps) =>
    <SvgIcon component={Icon} className={clsx(type, className)} viewBox={viewBox} {...props} />;

export const AddIcon = withSvgIconComponent(Add, "stroke");
export const AddCircleIcon = withSvgIconComponent(AddCircle, "stroke");
export const CalendarEditIcon = withSvgIconComponent(CalendarEdit, "stroke");
export const DeleteIcon = withSvgIconComponent(Delete, "stroke");
export const DeleteUserIcon = withSvgIconComponent(DeleteUser, "stroke");
export const EditIcon = withSvgIconComponent(Edit, "stroke", "-1 -1 26 26");
export const EditFilledIcon = withSvgIconComponent(EditFilled, null, "0 0 18 18");
export const MoveRightIcon = withSvgIconComponent(MoveRight, "stroke");
export const SendInvitationIcon = withSvgIconComponent(SendInvite, "stroke");
export const ArchiveUserIcon = withSvgIconComponent(ArchiveUser, "stroke");
export const ArchiveInvitationIcon = withSvgIconComponent(ArchiveDrawer, "stroke");
export const CreditArchivedIcon = withSvgIconComponent(CreditArchived, "stroke");
export const ResetPasswordIcon = withSvgIconComponent(ResetPassword, "stroke");
export const ViewIcon = withSvgIconComponent(View, "stroke");
export const ViewOffIcon = withSvgIconComponent(ViewOff, "stroke");
export const TemplateDownloadIcon = withSvgIconComponent(TemplateDownload, "stroke");
export const RemoveIcon = withSvgIconComponent(Remove, "stroke");
export const AwardRibbonIcon = withSvgIconComponent(AwardRibbon, "stroke");
export const CertifiedCertificateIcon = withSvgIconComponent(CertifiedCertificate, "stroke");
export const FileDownloadIcon = withSvgIconComponent(FileDownload, "stroke");
export const InfoIcon = withSvgIconComponent(Info, "stroke");
export const PencilCircleIcon = withSvgIconComponent(PencilCircle, "stroke");
export const UploadSquareIcon = withSvgIconComponent(UploadSquare, "stroke");
export const ContactCardIcon = withSvgIconComponent(ContactCard, "stroke");
export const ContactPhoneIcon = withSvgIconComponent(ContactPhone, "stroke");
export const EmailIcon = withSvgIconComponent(Email, "stroke", "0 0 22 22");
export const ContactWebSiteIcon = withSvgIconComponent(ContactWebSite, "stroke");
export const CardViewIcon = withSvgIconComponent(CardView, "stroke", "0 0 20 20");
export const TableViewIcon = withSvgIconComponent(TableView, "stroke", "0 0 20 20");
export const AdvancedCreditIcon = withSvgIconComponent(AdvancedCredit);
export const FoundationalCreditIcon = withSvgIconComponent(FoundationalCredit);
export const UploadBoxIcon = withSvgIconComponent(UploadBox, null, "0 0 74 74");
export const FilterIcon = withSvgIconComponent(Filter, "stroke");
export const SearchIcon = withSvgIconComponent(Search, "stroke");
export const AddPhotoIcon = withSvgIconComponent(AddPhoto, null, "0 0 23 18");
export const CalendarIcon = withSvgIconComponent(Calendar, "stroke", "0 0 18 18");
export const CheckIcon = withSvgIconComponent(Check, "stroke");
export const BlockIcon = withSvgIconComponent(Block, "stroke", "0 0 22 22");
export const CloseIcon = withSvgIconComponent(Close, "stroke");
export const KeyboardArrowUpDown = withSvgIconComponent(KeyboardArrowUp, "stroke");
export const ArrowsDownBoldIcon = withSvgIconComponent(ArrowsDownBold);
export const LockIcon = withSvgIconComponent(Lock, "stroke");
export const LockUnlockIcon = withSvgIconComponent(LockUnlock, "stroke");
export const NavigationLeftIcon = withSvgIconComponent(NavigationLeft, "stroke");
export const VerifiedBadgeIcon = withSvgIconComponent(VerifiedBadge);
export const EmailSentIcon = withSvgIconComponent(EmailSent, null, "0 0 86 86");
export const MenuButtonIcon = withSvgIconComponent(MenuButton, null, "0 0 20 18");
export const MenuSchoolIcon = withSvgIconComponent(MenuSchool);
export const MenuAdminsIcon = withSvgIconComponent(MenuAdmins);
export const MenuLearnerIcon = withSvgIconComponent(MenuLearner);
export const MenuSchoolsAdminsIcon = withSvgIconComponent(MenuSchoolsAdmins);
export const MenuEducatorIcon = withSvgIconComponent(MenuEducator);
export const MenuCoursesIcon = withSvgIconComponent(MenuCourses, "stroke");
export const MenuMcaIcon = withSvgIconComponent(MenuMca, "stroke", "0 0 140 140");
export const MenuCreditsIcon = withSvgIconComponent(MenuCredits, "stroke", "0 0 25 24");
export const MenuCreditAreasIcon = withSvgIconComponent(MenuCreditAreas, "stroke");
export const DashboardIcon = withSvgIconComponent(Dashboard, null, "0 0 19 19");
export const SchoolPlaceholderIcon = withSvgIconComponent(SchoolPlaceholder, null, "0 0 91 91");
export const QuestionCircleIcon = withSvgIconComponent(QuestionCircle, "stroke", "0 0 24 26");
export const CreditLibraryHiddenIcon = withSvgIconComponent(CreditLibraryHidden, null, "0 0 18 20");
export const CreditsExcludedIcon = withSvgIconComponent(CreditsExcluded, null, "0 0 20 20");
export const TranscriptPublishHiddenIcon = withSvgIconComponent(TranscriptPublishHidden, null, "0 0 21 18");
export const ErrorTranscriptFieldIcon = withSvgIconComponent(ErrorTranscriptField, "stroke", "0 0 18 18");
export const WarningTranscriptFieldIcon = withSvgIconComponent(WarningTranscriptField, "stroke", "0 0 18 18");
export const OfficeFilePdfIcon = withSvgIconComponent(OfficeFilePdf, "stroke", "0 0 25 25");
export const PublishPDFIcon = withSvgIconComponent(PublishPDF, "stroke", "0 0 22 22");
export const ViewCircleIcon = withSvgIconComponent(ViewCircle, "stroke", "0 0 22 22");
export const FileAttachmentIcon = withSvgIconComponent(FileAttachment, "stroke");
export const MenuCollegesIcon = withSvgIconComponent(MenuColleges, "stroke", "0 0 22 20");
export const FeedAllIcon = withSvgIconComponent(FeedAll, "stroke", "0 0 24 25");
export const FeedRequiredActionIcon = withSvgIconComponent(FeedRequiredAction, "stroke", "0 0 16 25");
export const CourseEventChipIcon = withSvgIconComponent(CourseEventChip, null, "0 0 13 14");
export const EvidenceEventChipIcon = withSvgIconComponent(EvidenceEventChip, null, "0 0 12 12");
export const FoundationalCreditEventChipIcon = withSvgIconComponent(FoundationalCreditEventChip, null, "0 0 12 8");
export const InitiatorEventChipIcon = withSvgIconComponent(InitiatorEventChip, null, "0 0 12 12");
export const LearnerEventChipIcon = withSvgIconComponent(LearnerEventChip, null, "0 0 12 12");
export const AdvancedCreditEventChipIconIcon = withSvgIconComponent(AdvancedCreditEventChipIcon, null, "0 0 14 9");
export const MapViewIcon = withSvgIconComponent(MapView, "stroke", "0 0 20 20");
export const DenyIcon = withSvgIconComponent(Deny, "stroke", "0 0 22 22");
export const UpdateSchoolsListIcon = withSvgIconComponent(UpdateSchoolsList, "stroke", "0 0 22 22");
export const GoogleIcon = withSvgIconComponent(Google, null, "0 0 20 20");
export const EditSchoolContactsTabIcon = withSvgIconComponent(EditSchoolContactsTab, "stroke", "0 0 26 24");
export const EditSchoolGeneralTabIcon = withSvgIconComponent(EditSchoolGeneralTab, "stroke");
export const EditSchoolInfoTabIcon = withSvgIconComponent(EditSchoolInfoTab, null, "0 0 24 24");
export const EditSchoolMcaTabIcon = withSvgIconComponent(EditSchoolMcaTab, null, "0 0 25 24");
export const EditSchoolNumbersTabIcon = withSvgIconComponent(EditSchoolNumbersTab, "stroke", "0 0 25 24");
export const EditSchoolSystemsTabIcon = withSvgIconComponent(EditSchoolSystemsTab, "stroke", "0 0 25 24");
export const CollegeEventChipIcon = withSvgIconComponent(CollegeEventChip, null, "0 0 12 13");
export const RestoreIcon = withSvgIconComponent(Restore, "stroke", "0 0 24 21");
export const AccessPagePDfIcon = withSvgIconComponent(AccessPagePDf, "stroke", "0 0 22 22");
export const ClrDownloadIcon = withSvgIconComponent(ClrDownload, "stroke");
export const AddCreditLabelIcon = withSvgIconComponent(AddCreditLabel, "stroke");
export const MarkAsFeaturedCreditIcon = withSvgIconComponent(MarkAsFeaturedCredit, "stroke", "0 0 20 20");
export const MarkAsNotFeaturedCreditIcon = withSvgIconComponent(markAsNotFeaturedCredit, "stroke", "0 0 20 20");
export const MenuLiveFeedIcon = withSvgIconComponent(MenuLiveFeed, "stroke", "0 0 24 19");
export const CommentIcon = withSvgIconComponent(Comment, "stroke", "0 0 22 22");
export const GroupIcon = withSvgIconComponent(Group, "stroke", "0 0 20 20");
export const EditorToolbarAttachImageIcon = withSvgIconComponent(EditorToolbarAttachImage, "stroke", "0 0 22 22");
export const FileIcon = withSvgIconComponent(File, "stroke", "0 0 24 24");
export const FolderIcon = withSvgIconComponent(Folder, "stroke", "0 0 24 22");
export const MenuMediaLibraryIcon = withSvgIconComponent(MenuMediaLibrary, "stroke", "0 0 20 20");
export const ExternalLinkIcon = withSvgIconComponent(ExternalLink, "stroke", "0 0 24 24");
export const LibraryDownloadIcon = withSvgIconComponent(LibraryDownload, "stroke", "0 0 20 22");
export const MessagesIcon = withSvgIconComponent(Messages, "stroke", "0 0 24 24");
export const LoginAsIcon = withSvgIconComponent(LoginAs, "stroke", "0 0 25 24");
export const LikeIcon = withSvgIconComponent(Like, "stroke", "0 0 22 20");
export const MenuGrowIcon = withSvgIconComponent(MenuGrow, "stroke", "0 0 20 20");
export const GrowCollectionIcon = withSvgIconComponent(GrowCollection, "stroke", "0 0 20 20");
export const LeaveIcon = withSvgIconComponent(Leave, "stroke");
export const ChangeStatusIcon = withSvgIconComponent(ChangeStatus, "stroke");
export const CheckCircleIcon = withSvgIconComponent(CheckCircle, "stroke");
export const MarkAsDemoIcon = withSvgIconComponent(MarkAsDemo, "stroke");
export const MenuSchoolEventIcon = withSvgIconComponent(MenuEvents, "stroke");
export const TaskChecklistIcon = withSvgIconComponent(TaskChecklist, "stroke");
export const RetryIcon = withSvgIconComponent(Retry, "stroke", "0 0 25 24");
export const UnreadIndicatorIcon = withSvgIconComponent(UnreadIndicator);
export const LogoIcon = withSvgIconComponent(Logo, null, "0 0 230 77");
export const MobileLogoIcon = withSvgIconComponent(MobileLogo, null, "0 0 57 57");
export const EnableTypesIcon = withSvgIconComponent(EnableCreditTypes, "stroke");
export const DisableTypesIcon = withSvgIconComponent(DisableCreditTypes, "stroke");
export const FileCheckIcon = withSvgIconComponent(CommonFileCheck, "stroke");
export const FileWarningIcon = withSvgIconComponent(CommonFileWarning, "stroke");
export const FileDisableIcon = withSvgIconComponent(CommonFileDisable, "stroke");
export const DeleteCircleIcon = withSvgIconComponent(DeleteCircle, "stroke");
export const StellarIcon = withSvgIconComponent(Stellar);
export const EmptyStellarIcon = withSvgIconComponent(EmptyStellar, "stroke");
export const CalendarClockIcon = withSvgIconComponent(CalendarClock, "stroke");
export const AnalyticsBarsHorizontalIcon = withSvgIconComponent(AnalyticsBarsHorizontal, "stroke");
export const PriorityCreditIcon = withSvgIconComponent(PriorityCredit, "stroke");
export const UnsyncedProgressIcon = withSvgIconComponent(UnsyncedProgress, "stroke");
export const AddDemonstratedLevelIcon = withSvgIconComponent(AddDemonstratedLevel, "stroke");
export const BackIcon = withSvgIconComponent(Back, "stroke", "0 0 25 25");
export const WhiteAnalyticBarsIcon = withSvgIconComponent(AnalyticBarsWhite, "stroke", "0 0 24 25");
export const WhiteLogoIcon = withSvgIconComponent(WhiteLogo, null, "0 0 57 57");
export const LearningScaleEnabledIcon = withSvgIconComponent(LearningScaleEnabled, "stroke", "0 0 20 20");
